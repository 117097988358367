<template>
  <edit-template type="card" v-loading="loading4">
    <div class="top-info p-3" v-if="isEdit">
      <LineWrapper class="list1" primary>
        <LineItem :label="item.label" :value="item.value" :color="item.color" v-for="item in list1" :key="item.label"></LineItem>
      </LineWrapper>
      <el-button type="primary" @click="handleView(1)">查看审批记录</el-button>
    </div>
    <div class="p-3">
      <el-form ref="form" :model="formData" :rules="rules" labelWidth="150rem" label-position="left">
        <template v-if="!isEdit">
          <el-form-item label="学生姓名" prop="student_name">
            <el-input v-model="formData.student_name" maxlength="20" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="学生身份证号" prop="id_card">
            <el-input v-model="formData.id_card" maxlength="18" placeholder="请输入"></el-input>
          </el-form-item>
        </template>
        <el-form-item label="生活费类型" prop="fee_type">
          <el-select v-model="formData.fee_type" placeholder="请选择">
            <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <div class="flex">
          <div class="mr-5">
            <el-form-item label="方案分类" prop="category">
              <el-select v-model="formData.category" placeholder="请选择">
                <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="缴费方式" prop="mode">
              <el-select v-model="formData.mode" placeholder="请选择">
                <el-option v-for="item in options3" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
          </div>
        </div>
        <div class="flex">
          <div class="mr-5">
            <el-form-item class="mb-0" label="收费方案" prop="fee_plan_id" v-loading="loading1">
              <el-select v-model="formData.fee_plan_id" placeholder="请选择">
                <el-option v-for="item in options4" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
          </div>
          <div class="flex">
            <el-button type="primary" :disabled="!formData.fee_plan_id" @click="handleView(2)">预览收费方案</el-button>
            <el-button class="mr-3" :disabled="!formData.view" @click="handleReset">重置方案</el-button>
            <el-form-item class="mb-0" label="是否已预览方案" prop="view">
              <div style="width: 120rem;">{{ formData.view ? '是' : '否' }}</div>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
    <Dialog :visible.sync="visible2" :loading="loading2" size="big" title="预览收费方案" submitText="我已知晓" buttonAlign="center" :showCancelButton="false" :closeOnClickModal="false" @submit="handleDialogSubmit">
      <div class="dialog-list">
        <div class="dialog-item" v-for="item in list2" :key="item.label">
          <div class="label">{{ item.label }}</div>
          <div class="value">{{ item.value }}</div>
        </div>
      </div>
      <div class="mb-2"></div>
      <DetailTable border :data="tableData2" :config="tableConfig2"></DetailTable>
    </Dialog>
    <Dialog :visible.sync="visible1" :loading="loading3" size="big" title="查看审批记录" submitText="我知道了" buttonAlign="center" :showCancelButton="false">
      <DetailTable border :data="tableData1" :config="tableConfig1"></DetailTable>
    </Dialog>
    <template #footer>
      <el-button type="primary" @click="handleSubmit('form')">提交</el-button>
      <el-button @click="handleBack">取消</el-button>
    </template>
  </edit-template>
</template>

<script>
import Dialog from "@/components/common/Dialog"
import LineWrapper from "@/components/common/LineWrapper"
import LineItem from "@/components/common/LineItem"
import DetailTable from "@/components/common/DetailTable"
import { formDataBackMixin } from "@/utils/mixins"
import { zsApproveStatusColor } from "@/utils/data"
import { zsGet, zsPost } from "@/api"
import { changeOption } from "@/utils"
import { validatorIdCard, validatorName } from "@/utils/validate"
export default {
	_config:{"route":[{"path":"edit","meta":{"title":"编辑"}}, {"path":"add","meta":{"title":"新增"}}]},
  components: { Dialog, LineWrapper, LineItem, DetailTable },
  mixins: [formDataBackMixin],
  data() {
    return {
      isEdit: false,
      isFirst: false,
      visible1: false,
      visible2: false,
      isLoaded1: false,
      isLoaded2: false,
      formData: { student_name: '', id_card: '', fee_type: '', category: '', mode: '', fee_plan_id: '', view: '' },
      rules: {
        student_name: [{ required: true, validator: validatorName, trigger: 'blur' }],
        id_card: [{ required: true, validator: validatorIdCard, trigger: 'blur' }],
        fee_type: [{ required: true, message: '请选择', trigger: 'change' }],
        category: [{ required: true, message: '请选择', trigger: 'change' }],
        mode: [{ required: true, message: '请选择', trigger: 'change' }],
        fee_plan_id: [{ required: true, message: '请选择', trigger: 'change' }],
        view: [{ required: true, message: '请先预览收费方案', trigger: 'change' }],
      },
      options1: [],
      options2: [],
      options3: [],
      options4: [],
      loading1: false,
      loading2: false,
      loading3: false,
      loading4: false,
      list1: [],
      list2: [],
      tableConfig2: [
        { prop: 'school_year_name', label: '收费学年' },
        { prop: 'charge_date', label: '收费日期' },
        { prop: 'fee_combination_name', label: '科目组合名称' },
        { prop: 'payable_price', label: '应付金额' },
        { prop: 'reduction_price', label: '减免金额' },
        { prop: 'actual_price', label: '实付金额' }
      ],
      tableConfig1: [
        { prop: 'approver', label: '审批人' },
        { prop: 'updated_at', label: '审批时间' },
        { prop: 'approve_status_text', label: '审批结果' },
        { prop: 'remark', label: '备注说明' }
      ],
      tableData1: [],
      tableData2: []
    }
  },
  watch: {
    'formData.category'() {
      this.getPlanOptions()
    },
    'formData.mode'() {
      this.getPlanOptions()
    },
    'formData.fee_plan_id'() {
      this.isLoaded1 = false
      this.list2 = []
      this.tableData2 = []
      if(!this.isFirst) this.formData.view = ''
    }
  },
  created() {
    if(this.$route.path.includes('edit')) {
      this.isEdit = true
      this.isFirst = true
      this.getData()
    }
    this.getConfig()
  },
  methods: {
    getConfig() {
      zsGet('/finance/fee-stu/get-map').then(res => {
        const { category, mode, fee_type } = res
        this.options1 = changeOption({ list: fee_type, label: 'fee_type_name', value: 'id' })
        this.options2 = changeOption({ list: category, label: 'category_name', value: 'id' })
        this.options3 = changeOption({ list: mode, label: 'mode_name', value: 'id' })
      })
    },
    getPlanOptions() {
      if(this.formData.mode === '' || this.formData.category === '') return
      if(!this.isFirst) {
        this.options4 = []
        this.formData.fee_plan_id = ''
        this.$nextTick(() =>this.$refs['form'].clearValidate(['fee_plan_id']))
      }
      this.loading1 = true
      zsGet('/finance/fee-stu/search-plan', { mode: this.formData.mode, category: this.formData.category }).then(res => {
        this.options4 = changeOption({ list: res, label: 'name', value: 'id' })
        this.isFirst = false
      }).finally(() => this.loading1=false)
    },
    getPlanList() {
      if(!this.formData.fee_plan_id || this.isLoaded1) return
      this.loading2 = true
      zsGet('/finance/fee-stu/get-plan', { fee_plan_id: this.formData.fee_plan_id }).then(res => {
        const { total_amount, income_method_text, school_name, combination = [] } = res
        this.list2 = [{ label: '总金额', value: total_amount }, { label: '入账方式', value: income_method_text }, { label: '入账校区', value: school_name }]
        this.tableData2 = combination
        this.isLoaded1 = true
      }).finally(() => this.loading2=false)
    },
    getRecord() {
      if(this.isLoaded2) return
      this.loading3 = true
      zsGet('/finance/fee-stu/approve-record', { fee_student_id: this.$route.query.id }).then(res => {
        this.tableData1 = res
        this.isLoaded2 = true
      }).finally(() => this.loading3=false)
    },
    getData() {
      this.loading4 = true
      zsGet('/finance/fee-stu/show', { id: this.$route.query.id }).then(res => {
        const { student_name, id_card, approve_status_text, fee_type, fee_plan_id, fee_plan: { mode, category} } = res
        this.list1 = [{ label: '学生姓名', value: student_name }, { label: '学生身份证号', value: id_card }, { label: '审核状态', value: approve_status_text, color: zsApproveStatusColor(approve_status_text) }]
        this.formData = { fee_type, fee_plan_id, mode, category, view: '1' }
        this.getPlanList()
      }).finally(() => this.loading4=false)
    },
    handleSubmit(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          const { student_name, id_card, fee_type, fee_plan_id } = this.formData
          const data = { fee_type, fee_plan_id }
          if(this.isEdit) {
            data.id = this.$route.query.id
          } else {
            data.student_name = student_name
            data.id_card = id_card
          }
          zsPost(`/finance/fee-stu/${this.isEdit ? 'edit' : 'add'}`, data).then(() => {
            this.$message.success('操作成功')
            this.$router.back()
          })
        }
      })
    },
    handleBack() {
      this.formBack(this.formData)
      // this.$router.back()
    },
    handleView(type) {
      this['visible' + type] = true
      if(type === 1) this.getRecord()
      else if(type === 2) this.getPlanList()
    },
    handleReset() {
      this.formData.view = ''
      this.$refs['form'].clearValidate(['view'])
    },
    handleDialogSubmit() {
      this.formData.view = '1'
      this.$refs['form'].clearValidate(['view'])
    }
  },
}
</script>

<style lang="scss" scoped>
.top-info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20rem 100rem;
  border-bottom: 20rem solid #f7f7f7;
}
.list1 {
  margin: -10rem 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0 20rem;
}
.dialog {
  &-list {
    display: flex;
    margin-bottom: 10rem;
  }
  &-item {
    display: flex;
    &:not(:last-child) {
      margin-right: 60rem;
    }
    .label {
      margin-right: 20rem;
    }
    .value {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
